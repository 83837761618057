/**
 * Provides individual card image.
 * @param cardType The type of credit card.
 * @returns The image URL for the corresponding credit card type.
 * @remarks Some card types are networked together and have only one image, ex: DISCOVER|JCB|UNIONPAY
 * @category Utils
 */
export const getCreditCardImage = (cardType) => {
  switch (cardType.toUpperCase()) {
    case 'VISA':
      return '/img/credit-cards/visa.svg'
    case 'MAESTRO':
      return '/img/credit-cards/maestro.svg'
    case 'MC':
    case 'MASTERCARD':
      return '/img/credit-cards/mastercard.svg'
    case 'AMEX':
      return '/img/credit-cards/amex.svg'
    case 'DISCOVER':
    case 'JCB':
    case 'UNIONPAY':
      return '/img/credit-cards/discover.svg'
    case 'DINERS':
      return '/img/credit-cards/diners.svg'
  }
}

/**
 * Provides a consolidated, ordered list of all.
 * @param cards Optional array of credit cards.
 * @returns An array of objects containing credit card method names and corresponding image URLs.
 * @remakes Card images configured in brand configs.
 * @category Utils
 */
export const getCreditCardImages = (cards?) => {
  const acceptedCards = cards || useAppConfig().checkout.creditCards.imageGroup[useCountryCode()]
  const pictures: { method: string, picture: string }[] = []
  acceptedCards.forEach((card) => {
    const picture = getCreditCardImage(card.card_type)
    if (picture)
      pictures.push({ method: card.name, picture })
  })
  return pictures
}
